// Scripts required for the website, which require the dom to be fully loaded.
import * as siteConfig from "./_variable";
import { Carousel } from "bootstrap";

ready(() => {

  /**
   * HERO CAROUSEL:
   * Show the images defined in the variable file. If there is only a single image defined we will not initialise
   * the carousel as there is nothing to cycle through.
   */
  let heroCarousel = document.querySelector('#hero-carousel');
  if (heroCarousel !== null) {

    // Make sure the object is specified in the variable file.
    if (typeof siteConfig.heroCarousel !== 'undefined') {
      siteConfig.heroCarousel.forEach((el, index) => {

        // Add the images to the carousel as slides.
        let slideContainer = heroCarousel.querySelector('.carousel-inner');
        if (slideContainer !== null) {

          slideContainer.insertAdjacentHTML('beforeend', ' \
            <div class="carousel-item ' + ((index === 0) ? 'active' : '') + '"> \
              <img src="' + el.img + '" class="d-block w-100" alt="' + el.label + '" style="' + el.style + '"> \
              <div class="carousel-caption d-md-block"> \
                <h1>' + el.label + '</h1> \
              </div> \
            </div>');
        }
      });

      // Initialise the carousel if there are multiple slides, else delete the slide indicators.
      if (siteConfig.heroCarousel.length > 1) {

        let heroBootstrapCarousel = new Carousel(heroCarousel, {
          ride: 'carousel'
        });

      } else {

        let pageIndicator = heroCarousel.querySelector('.carousel-indicators');
        if (pageIndicator !== null) {
          pageIndicator.remove();
        }
      }
    }
  }


  /**
   * CAROUSEL COMPONENT:
   * We need to override some bootstrap carousel functionality for the section "Our group of companies".
   * however, not every page has this section, so only run this if it's actually required.
   */
  if (document.querySelector('#our-group-of-companies-carousel') !== null) {

    // Delete any logo that we not wish to display.
    if (typeof siteConfig.removeFromOurGroupOfCompanies !== 'undefined') {
      siteConfig.removeFromOurGroupOfCompanies.forEach(el => {
        document.querySelectorAll('#our-group-of-companies-carousel img[alt="' + el + '" i]').forEach((el) => {
          el.closest('.carousel-item').remove();
        });
      });
    }

    // The work-around for displaying 4 items rather than 1, is to clone each of the items into their neighbours container.
    // Also, set the active state on the first item, we can't do this in the code because the above selector might delete it.
    let items = document.querySelectorAll('#our-group-of-companies-carousel .carousel-item');
    if (items.length > 0) {
      items[0].classList.add('active');
      items.forEach((el) => {
        const minPerSlide = 4;
        let next = el.nextElementSibling
        for (let i = 1; i < minPerSlide; i++) {
          if (!next) {
            // wrap carousel by using first child.
            next = items[0]
          }

          let cloneChild = next.cloneNode(true)
          el.appendChild(cloneChild.children[0])
          next = next.nextElementSibling
        }
      });
    }
  }
});