import '../scss/index.scss'
import './_utilities.js';
import './_nav.js';
import './_services.js';
import './_carousel.js';
import './_locations.js';
import './_news.js';
import './_contact.js';
import './_company-history-timeline.js';
import './_join-the-conversation.js';
import * as siteConfig from "./_variable";
import './_topbutton.js';

// Used to show the company's YouTube video in a lightbox.
require("fslightbox");

// Scripts required for the website, which require the dom to be fully loaded.
ready(() => {

  // Google Analytics.
  window.dataLayer = window.dataLayer || [];
  function gtag(){ dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', siteConfig.gTagKey);

  // Initialise the lightbox with our YouTube video.
  refreshFsLightbox();

  // Search and replace various static pieces of data into the website.
  // Create a span and give it a class as per below list of items, the data will then automatically be added to the dom.
  document.querySelectorAll('.current-year').forEach(el => {
    el.innerText = new Date().getFullYear();
  });

  document.querySelectorAll('.company-legal-name').forEach(el => {
    el.innerText = siteConfig.company.legalName;
  });

  // Show Quality Policy if Graypen/GP Shipping or BMA.
  if (siteConfig.removeQualityPolicy === true) {
    let qualityPolicyPDF = document.getElementById('QualityPolicyPDF');
    if (qualityPolicyPDF !== null) {
      qualityPolicyPDF.remove();
    }
  }

  // Remove social media.
  if (siteConfig.removeSocialMedia === true) {
    let gplay = document.getElementById('gplay');
    let appstore = document.getElementById('appstore');
    let youtube = document.getElementById('youtubeLink');
    if (gplay !== null) {
      gplay.remove();
    }
    if (appstore !== null) {
      appstore.remove();
    }
    if (youtube !== null) {
      youtube.remove();
    }
  }

  // Hide the news part of join the conversation if you dont want it to show (GPL customs).
  if (siteConfig.hideNews === true) {
    let newsGPC = document.getElementById('join-the-conversation-news');
    if (newsGPC !== null) {
      newsGPC.remove();
    }
  }

  function removeAboutUsLoadingspinner()
  {
    let loadingSpinner = document.getElementById('about-us-loading-spinner');
    if (loadingSpinner !== null) {
      loadingSpinner.remove();
    }
  }

  document.querySelectorAll('.content-about-us').forEach(el => {
    fetchApiData(siteConfig.api.domain + 'companies/' + siteConfig.api.company + '?referrer=' + siteConfig.api.referrer).then(company => {
      el.innerText = company['description'];
      removeAboutUsLoadingspinner();
    }).catch(() => {
      el.innerHTML = '<div class="col-12 text-danger">Something went wrong, we couldn\'t load the about us data. Please try again in a few minutes.</div>';
      removeAboutUsLoadingspinner();
    });
  });

  document.querySelectorAll('.company-registered-in-country').forEach(el => {
    el.innerText = siteConfig.company.registeredInCountry;
  });

  document.querySelectorAll('.company-registration-number').forEach(el => {
    el.innerText = siteConfig.company.registrationNumber;
  });

  document.querySelectorAll('.company-registered-address').forEach(el => {
    el.innerHTML = siteConfig.company.registeredAddress;
  });

  let htmlTitle = document.querySelector('title');
  if (htmlTitle !== null) {
    if (htmlTitle.innerText !== '') {
      htmlTitle.innerText = siteConfig.htmlHeadTitle.replace('{pageTitle}', (htmlTitle.innerText + ' | '));
    } else {
      htmlTitle.innerText = siteConfig.htmlHeadTitle.replace('{pageTitle}', '').trim();
    }
  }
});