import * as siteConfig from "./_variable";

// Open or close the mobile menu depending on the current state.
window.toggleMobileMenu = function(el, force) {
  let expanded = el.getAttribute('data-expanded');
  if (expanded === 'true' || force) {
    // Closing
    document.querySelector('#collapsable-menu').classList.add('d-none');
    el.setAttribute('data-expanded', 'false');
    el.querySelector('svg:first-child').classList.add('d-none');
    el.querySelector('svg:last-child').classList.remove('d-none');
    document.querySelector('body').classList.remove('body-no-overflow');
  } else {
    // Opening
    document.querySelector('#collapsable-menu').classList.remove('d-none');
    el.setAttribute('data-expanded', 'true');
    el.querySelector('svg:first-child').classList.remove('d-none');
    el.querySelector('svg:last-child').classList.add('d-none');
    document.querySelector('body').classList.add('body-no-overflow');
  }
};

// Set the social media URL, if no url given or an error occured than delete the social media icon.
window.setSocialLink = function(media) {
  fetchApiData(siteConfig.api.domain + 'companies/' + siteConfig.api.company + '?referrer=' + siteConfig.api.referrer).then(company => {
    document.querySelectorAll('.menu-container a.' + media).forEach(object => {
      if (company[media] !== '') {
        object.setAttribute('href', company[media]);
      } else {
        object.remove();
      }
    });
  }).catch(() => {
    document.querySelectorAll('.menu-container a.' + media).forEach(object => {
      object.remove();
    });
  });
};

// Scripts required for the website, which require the dom to be fully loaded.
ready(() => {

  /**
   * Iterate through the menu object in order to construct the menu.
   * Multiple nav items could be returned, for example: Main nav, mobile nav, and footer nav.
   */
  siteConfig.menu.forEach((el, index) => {

    // Iterate through all the menus and add the items.
    document.querySelectorAll('.menu-container nav').forEach((menu) => {

      // Not all menu items show in the main menu on the top, some only show in the footer.
      if (!el.show) {
        return;
      }

      // Add a separator line between the elements, which is basically every element except for the first.
      if (index > 0) {
        menu.insertAdjacentHTML('beforeend', '<div class="nav-separator"></div>');
      }

      //Add the menu item.
      if (!el.dropdown) {
        menu.insertAdjacentHTML('beforeend', '<div class="nav-item"><a onclick="toggleMobileMenu(document.querySelector(\'#mobileMenuToggleButton\'), true);" href="' + el.href + '">' + el.name + '</a></div>');
      } else {
        let dropdownItems = '';
        let svg = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>';
        for (let i = 0; i < el.dropdown.length; i++) {
          dropdownItems += '<a class="dropdown-links" href="' + el.dropdown[i].href + '">' + el.dropdown[i].name + '</a>'
        }
        menu.insertAdjacentHTML('beforeend', '<div class="gp-wrapper"><div class="nav-item"><div class="gp-dropdown"><a class="gp-dropbtn">'+ el.name + svg +'</a><div class="gp-dropdown-content">'+ dropdownItems +'</div></div></div></div>');
      }
    });

    // Add all the items to the footer.
    let footerMainMenu = document.querySelector('#footer-main-menu');
    let footerGetToKnowUs = document.querySelector('#footer-get-to-know-us');
    if ((footerMainMenu !== null) && (footerGetToKnowUs !== null)) {
      if (el.inGetToKnowUs) {
        footerGetToKnowUs.insertAdjacentHTML('beforeend', '<a href="' + el.href + '">' + el.name + '</a>');
      } else {
        if (el.dropdown) {
          let footerDropdownItems = '';
          for (let i = 0; i < el.dropdown.length; i++) {
            footerDropdownItems += '<a href="' + el.dropdown[i].href + '">' + el.dropdown[i].name + '</a>'
          }
          footerMainMenu.insertAdjacentHTML('beforeend', footerDropdownItems);
        } else {
          footerMainMenu.insertAdjacentHTML('beforeend', '<a href="' + el.href + '">' + el.name + '</a>');
        }
      }

      // Run the command to populate the footer services menu.
      if ((el.sub !== null) && (typeof window[el.sub.function] === 'function')) {
        window[el.sub.function]('#footer-services-list');
      }
    }
  });

  // If the main menu is empty we will delete it.
  document.querySelectorAll('.menu-container nav').forEach((menu) => {
    if (menu.children.length === 0) {
      let container = menu.closest('.menu-container');
      if (container !== null) {
        let children = container.children;
        for (let i = 1; i < children.length; i++) {
          children[i].remove();
        }
      }
    }
  });


  /**
   * Set the socials URL addresses as specified by the GRAPI.
   * Multiple socials are possible, as they might be located in both the menu and the footer.
   */
  if (siteConfig.showExtraButton) {
    let firstSocial = document.querySelector('.social-link.rounded-circle');
    let extraButton = '<a href="' + siteConfig.showExtraButton.href + '" class="social-link rounded-circle portal-menu-button">' + siteConfig.showExtraButton.name + '</a>';
    firstSocial.insertAdjacentHTML('beforebegin', extraButton);
    // Now put this button in the mobile menu.
    if (siteConfig.showExtraButton.showInMobMenu === true) {
      let lastMobMenuItem = document.querySelector('#collapsable-menu .nav-item:last-child');
      lastMobMenuItem.insertAdjacentHTML('afterend', '<div class="nav-item"><a href="' + siteConfig.showExtraButton.href + '">' + siteConfig.showExtraButton.name + '</a></div>');
      lastMobMenuItem.insertAdjacentHTML('afterend', '<div class="nav-separator"></div>');
    }
  }
  setSocialLink('twitter');
  setSocialLink('facebook');
  setSocialLink('linkedin');
});