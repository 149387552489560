import * as siteConfig from "./_variable";

// Execute the given function when the browser has finished loading the DOM.
window.ready = function(fn) {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

// Get the absolute position of an element on the page.
window.position = function(el) {
  const { top, left } = el.getBoundingClientRect();
  const { marginTop, marginLeft } = getComputedStyle(el);
  return {
    top: top - parseInt(marginTop, 10),
    left: left - parseInt(marginLeft, 10)
  };
};

// Returns true if the given string is JSON.
window.isJsonString = function(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// Get the previous sibling of the given element, optionally matching a given selector.
window.prev = function(el, selector) {
  if (selector) {
    const prev = el.previousElementSibling;
    if (prev && prev.matches(selector)) {
      return prev;
    }
    return undefined;
  } else {
    return el.previousElementSibling;
  }
};

//if staff member does not have photo, placeholder will be used.
window.showPlaceholderImage = function(erroredObject) {

  let parentContainer = erroredObject.closest('div');
  if (parentContainer === null) {
    return;
  }

  // Delete the image object, and replace it with a placeholder div.
  let classes = erroredObject.classList.toString()
  erroredObject.remove();
  parentContainer.insertAdjacentHTML('afterbegin', '<div class="' + classes + '"></div>');
}

// turns a string into a slug suitable for using in url.
// https://gist.github.com/codeguy/6684588?permalink_comment_id=4325476#gistcomment-4325476
window.slugify = function(text) {
  return text
    .toString()                   // Cast to string (optional)
    .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase()                // Convert the string to lowercase letters
    .trim()                       // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-')         // Replace spaces with -
    .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
    .replace(/\_/g,'-')           // Replace _ with -
    .replace(/\-\-+/g, '-')       // Replace multiple - with single -
    .replace(/\-$/g, '');         // Remove trailing -
};

// Make a GET request for data, and return JSON.
window.fetchApiData = async function(url) {

  // Check if our json data has been retrieved before, if so we can get it from the cache.
  let data = siteConfig.apiCaching ? sessionStorage.getItem(url) : null;
  if (data === null) {
    const response = await fetch(url);
    if (response.ok) {
      data = await response.text();
      sessionStorage.setItem(url, data);
    }
  }

  return JSON.parse(data);
};

// Returns the value of the given css variable name.
const cssRootScope = document.querySelector(':root');
const cssRootStyles = getComputedStyle(cssRootScope);
window.getCssVariable = function(property) {
  return cssRootStyles.getPropertyValue(property);
};

// Returns the next sibling of a given elements, optionally with a selector.
window.next = function(el, selector) {
  if (selector) {
    const next = el.nextElementSibling;
    if (next && next.matches(selector)) {
      return next;
    }
    return null;
  } else {
    return el.nextElementSibling;
  }
};

// Get the ordinal string for a given number.
window.getOrdinal = function(n) {
  let ord = [ 'st', 'nd', 'rd' ];
  let exceptions = [11, 12, 13];
  let nth = ((ord[(n % 10) - 1] === undefined) || exceptions.includes(n % 100)) ? 'th' : ord[(n % 10) - 1];
  return n + nth
};