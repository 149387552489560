import * as siteConfig from "./_variable";

// Cycle through the available timeline items using the left and right icons.
let historyItemSelected = 0;
window.moveHistoryTimeline = function(clickedObject, direction) {

  // If the chevron is disabled we will not execute anything.
  if (clickedObject.querySelector('svg').classList.contains('disabled')) {
    return;
  }

  // Move the selected item.
  historyItemSelected += direction;

  // Find all the history items.
  let items = document.querySelectorAll('.history-card-container');
  if (items.length === 0) {
    return;
  }

  // Make sure we haven't gone out of bounds.
  if (historyItemSelected < 0) {
    historyItemSelected = 0;
  }

  if (historyItemSelected >= items.length) {
    historyItemSelected = items.length - 1;
  }

  // Enable/disable chevrons based on current selected item.
  if (historyItemSelected === 0) {
    document.querySelector('#company-history-chevron-left').classList.add('disabled');
  } else {
    document.querySelector('#company-history-chevron-left').classList.remove('disabled');
  }

  // Make sure we haven't gone out of bounds on the right side.
  if (historyItemSelected === items.length - 1) {
    document.querySelector('#company-history-chevron-right').classList.add('disabled');
  } else {
    document.querySelector('#company-history-chevron-right').classList.remove('disabled');
  }

  // And do the actual moving.
  document.querySelector('#company-history-timeline-content').style.transform = 'translateX(' + -items[historyItemSelected].offsetLeft + 'px)';
};

// Scripts required for the website, which require the dom to be fully loaded.
ready(() => {

  window.timelineScrollWidth = 0;
  let timelineComponent = document.querySelector('#company-history-timeline');
  if (timelineComponent !== null) {

    // If there are no timeline items defined, delete the entire timeline component from the DOM.
    if (siteConfig.timeline.length === 0) {
      timelineComponent.remove();
    }

    // Iterate through all the history items defined in the variable file.
    let history = '';
    siteConfig.timeline.forEach((item, index) => {

      let itemDate = new Date(item.date);

      let historyCard = ' \
        <div class="history-card"> \
          <h2>' + itemDate.getFullYear() + '</h2> \
          <h3>' + item.text + '</h3> \
        </div>';

      let historyLine = '<span class="history-vertical-line"></span>';

      history += ' \
        <div class="history-card-container ' + (((index % 2) === 0) ? 'even' : 'odd')  + '"> \
          ' + historyCard + ' \
          ' + historyLine + ' \
          \
          <div class="history-horizontal-line"> \
            <div></div> \
          </div> \
          \
          ' + historyLine + ' \
          ' + historyCard + ' \
        </div>';
    });

    document.querySelector('#company-history-timeline-content').insertAdjacentHTML('beforeend', ' \
      <div id="company-history-timeline-left"></div> \
      ' + history + ' \
      <div id="company-history-timeline-right"></div>');

    // The end lines are always visible, if we have more items than the minimum specified we will have to hide the line.
    // As we always start with item 1 we never have to worry about the left side icons.
    if (siteConfig.timeline.length > siteConfig.timelineVisibleItems) {
      document.querySelector('#company-history-chevron-right').classList.remove('disabled');
    }

    // Calculate the width of the container containing all the history items.
    let container = document.querySelector('#company-history-timeline-container');
    window.timelineScrollWidth = container.scrollWidth - container.offsetWidth;

    // We need to wait a little bit so the browser has time to draw all the history items to screen.
    // After this has been done we can go through them and calculate the total amount of lines they have.
    setTimeout(function() {
      document.querySelectorAll('.history-card h3').forEach((item) => {
        let lineHeight = parseInt(getComputedStyle(item).lineHeight);
        let lines = Math.ceil(item.offsetHeight / lineHeight);
        if (lines > 2) {
          item.classList.add('line-clamp');
          item.insertAdjacentHTML('afterend', '<a href="javascript:void(0)" onclick="toggleHistoryReadMore(this)">Read More</a>');
        } else if (lines === 1) {
          item.insertAdjacentHTML('afterend', '<span class="invisible">&nbsp;</span>');
        }
      });
    }, 250);
  }
});

window.historyItemCurrentOpen = null;
window.toggleHistoryReadMore = function(clickedObject) {
  let historyCard = clickedObject.closest('.history-card');
  if (historyCard === null) {
    return;
  }

  // If this is already an opened card, simply delete it.
  if (historyCard.classList.contains('popOut')) {
    window.historyItemCurrentOpen = null;
    historyCard.remove();
    return;
  }

  // Close any previously opened.
  if (window.historyItemCurrentOpen !== null) {
    toggleHistoryReadMore(window.historyItemCurrentOpen);
  }

  window.historyItemCurrentOpen = historyCard.cloneNode(true);
  window.historyItemCurrentOpen.classList.add('popOut');

  let container = historyCard.closest('.history-card-container');
  if ((container !== null) && container.classList.contains('odd')) {
    window.historyItemCurrentOpen.classList.add('popUpwards');
  }

  let lineClamp = window.historyItemCurrentOpen.querySelector('h3.line-clamp');
  if (lineClamp !== null) {
    lineClamp.classList.remove('line-clamp');
  }

  let readMore = window.historyItemCurrentOpen.querySelector('a');
  if (readMore !== null) {
    readMore.innerText = 'Read Less';
  }

  historyCard.append(window.historyItemCurrentOpen);
};