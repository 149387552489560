// Run the following when the page has finished loading.
import * as siteConfig from "./_variable";

// Expand/Collapse the archive's year section.
window.toggleNewsArchiveYear = function(clickedObject) {

  // Expand if the object is currently collapsed.
  if (clickedObject.getAttribute('data-collapsed') === 'true') {
    clickedObject.setAttribute('data-collapsed', 'false');
    clickedObject.querySelector('svg:first-of-type').classList.add('d-none');
    clickedObject.querySelector('svg:last-of-type').classList.remove('d-none');
    document.querySelectorAll('div[data-year="' + clickedObject.getAttribute('data-year') + '"]:not(.year)').forEach(item => {

      // Show the months that are part of this year. But hide the news items within each month.
      if (item.classList.contains('item')) {
        item.classList.add('d-none');
      } else {
        item.classList.remove('d-none');
      }
    });

  } else {

    // Collapse everything associated with this year.
    clickedObject.setAttribute('data-collapsed', 'true');
    clickedObject.querySelector('svg:first-of-type').classList.remove('d-none');
    clickedObject.querySelector('svg:last-of-type').classList.add('d-none');
    document.querySelectorAll('div[data-year="' + clickedObject.getAttribute('data-year') + '"]:not(.year)').forEach(item => {
      if (!item.classList.contains('item')) {
        toggleNewsArchiveMonth(item, true);
        item.classList.add('d-none');
      }
    });
  }
};

// Expand/Collapse the archive's month section.
window.toggleNewsArchiveMonth = function(clickedObject, forceClose) {

  if (forceClose || (clickedObject.getAttribute('data-collapsed') === 'false')) {

    // Collapse everything associated with this year + month combo.
    clickedObject.setAttribute('data-collapsed', 'true');
    clickedObject.querySelector('svg:first-of-type').classList.remove('d-none');
    clickedObject.querySelector('svg:last-of-type').classList.add('d-none');
    document.querySelectorAll('div[data-year="' + clickedObject.getAttribute('data-year') + '"][data-month="' + clickedObject.getAttribute('data-month') + '"]:not(.month)').forEach(item => {
      item.classList.add('d-none');
    });
  } else {

    clickedObject.setAttribute('data-collapsed', 'false');
    clickedObject.querySelector('svg:first-of-type').classList.add('d-none');
    clickedObject.querySelector('svg:last-of-type').classList.remove('d-none');
    document.querySelectorAll('div[data-year="' + clickedObject.getAttribute('data-year') + '"][data-month="' + clickedObject.getAttribute('data-month') + '"]:not(.month)').forEach(item => {
      item.classList.remove('d-none');
    });
  }
};

// Remove the loading spinner.
window.removeNewsLoadingSpinner = function() {
  let newsLoadingSpinner = document.getElementById('news-loading-spinner');
  if (newsLoadingSpinner !== null) {
    newsLoadingSpinner.remove();
  }
};

// Get the HTML for the news item.
window.insertNewsItem = function(item) {

  // Parse the given date, as it's in an ISO format which isn't as nicely readable for humans.
  let creationDate = new Date(item.created_at);

  // Any images need to be shown underneath the news item. There is no limit to the total amount of images.
  let imageHtml = '';
  item.images.forEach(image => {
    imageHtml += '\
        <a data-fslightbox="' + item.slug + '" href="' + siteConfig.api.domain + 'news/' + siteConfig.api.company + '/' + item.id + '/image/' + image.image + '?referrer=' + siteConfig.api.referrer + '"> \
          <img class="news-image" src="' + siteConfig.api.domain + 'news/' + siteConfig.api.company + '/' + item.id + '/thumb/' + image.thumb + '?referrer=' + siteConfig.api.referrer + '" /> \
        </a>';
  });

  if (imageHtml !== '') {
    imageHtml = '<div class="d-flex flex-wrap gap-2 mt-3">' + imageHtml + '</div>';
  }

  let news = item.description;
  if (isJsonString(news)) {
    news = JSON.parse(news);
    news = news.text.text;
  }

  // Add the news item into the dom.
  document.getElementById('news-items-container').insertAdjacentHTML('beforeend', ' \
          <div class="news-item"> \
            <h3 class="fw-bold">' + getOrdinal(creationDate.getDate()) + ' ' + creationDate.toLocaleString('default', { month: 'long' }) + ' ' + creationDate.getFullYear() + '</h3> \
            <h2>' + item.title + '</h2> \
            <div>' + news + '</div> \
            ' + imageHtml + ' \
          </div>');
};

// When clicking a news item from the archive, load said news item into the main content.
window.clickNewsItem = function(news_id) {

  // Retrieve the container in which we will be loading this single news item.
  let newsContainer = document.querySelector('#news-items-container');
  if (newsContainer === null) {
    return;
  }

  // Show a loading spinner.
  newsContainer.innerHTML = '<div id="news-loading-spinner" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';

  // Get the specified news item.
  fetchApiData(siteConfig.api.domain + 'news_by_slug/' + siteConfig.api.company + '/' + news_id + '?referrer=' + siteConfig.api.referrer).then(items => {
    items.forEach(item => {
      insertNewsItem(item);
    });

    removeNewsLoadingSpinner();

    window.scrollTo(0, 0);
    refreshFsLightbox();
  }).catch(() => {
    newsContainer.innerHTML = '<div class="col-12 text-danger">Something went wrong, we couldn\'t load the latest news. Please try again in a few minutes.</div>';
    removeNewsLoadingSpinner();
  });
};

// Run the build up of the page once the page is finished loading.
ready(() => {

  // We only run this code if we're on the news page.
  let newsContainer = document.querySelector('#news-container');
  if (newsContainer !== null) {

    // build up the archive side plugin. If it exists.
    let archiveContainer = document.querySelector('#news-archive-container');
    if (archiveContainer !== null) {
      let currentYear = '';
      let currentMonth = '';
      fetchApiData(siteConfig.api.domain + 'news/' + siteConfig.api.company + '/archive?referrer=' + siteConfig.api.referrer).then(items => {
        items.forEach(item => {

          // Add a year heading if our current iteration is a different year than the previous.
          let creationDate = new Date(item.created_at);
          if (currentYear !== creationDate.getFullYear().toString()) {
            currentYear = creationDate.getFullYear().toString();
            archiveContainer.insertAdjacentHTML('beforeend', ' \
              <div class="archive-heading year" onclick="toggleNewsArchiveYear(this);" data-collapsed="true" data-year="' + creationDate.getFullYear().toString() + '"> \
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> \
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> \
                </svg> \
                \
                <svg class="d-none" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> \
                  <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/> \
                </svg> \
                \
                <div> \
                  <h2>' + currentYear + '</h2> \
                  <h3><span class="calculate-news-items">0</span> News Items</h3> \
                </div> \
              </div>');
          }

          // If the month + year is different than what we had previously then we have to add another heading.
          if (currentMonth !== (creationDate.getFullYear().toString() + creationDate.getMonth().toString())) {
            currentMonth = creationDate.getFullYear().toString() + creationDate.getMonth().toString();
            archiveContainer.insertAdjacentHTML('beforeend', ' \
              <div class="archive-heading month d-none" data-year="' + creationDate.getFullYear().toString() + '" data-month="' + creationDate.getMonth().toString() + '" onclick="toggleNewsArchiveMonth(this);" data-collapsed="true"> \
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> \
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> \
                </svg> \
                \
                <svg class="d-none" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> \
                  <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/> \
                </svg> \
                \
                <div> \
                  <h2>' + creationDate.toLocaleString('default', { month: 'long' }) + '</h2> \
                  <h3><span class="calculate-news-items">0</span> News Items</h3> \
                </div> \
              </div>');
          }

          // Add the news item.
          archiveContainer.insertAdjacentHTML('beforeend', ' \
            <div onclick="clickNewsItem(\'' + item.id + '\');" class="archive-heading item d-none" data-year="' + creationDate.getFullYear().toString() + '" data-month="' + creationDate.getMonth().toString() + '"> \
              <div class="fw-bold">' + creationDate.getDate().toString().padStart(2, '0') + '/' + creationDate.getMonth().toString().padStart(2, '0') + '/' + creationDate.getFullYear() + '</div> \
              <div class="text-truncate">' + item.title + '</div> \
            </div>');
        });

        // Add the archive heading.
        archiveContainer.insertAdjacentHTML('afterbegin', '<h2 class="archive-heading-h2">Archive</h2>');

        // Now that the archive has been loaded, click the first year and the first month within this first year.
        let firstYear = document.querySelectorAll('#news-archive-container > div.year');
        if (firstYear.length > 0) {
          firstYear[0].click();
          let firstMonth = document.querySelectorAll('#news-archive-container div.month[data-year="' + firstYear[0].getAttribute('data-year') + '"]');
          if (firstMonth.length > 0) {
            firstMonth[0].click();
          }
        }

        // Calculate how many news items there are per category.
        document.querySelectorAll('.calculate-news-items').forEach(item => {
          let parentContainer = item.closest('div.archive-heading');
          if (parentContainer === null) {
            return;
          }

          let year = parentContainer.getAttribute('data-year');
          let month = parentContainer.getAttribute('data-month');

          if (month === null) {
            item.innerText = document.querySelectorAll('div.archive-heading.item[data-year="' + year + '"]').length;
          } else {
            item.innerText = document.querySelectorAll('div.archive-heading.item[data-year="' + year + '"][data-month="' + month + '"]').length;
          }
        });

        removeNewsLoadingSpinner();
      }).catch(() => {
        newsContainer.innerHTML = '<div class="col-12 text-danger">Something went wrong, we couldn\'t load the latest news. Please try again in a few minutes.</div>';
        removeNewsLoadingSpinner();
      });
    }

    // Retrieve all the news from the API.
    fetchApiData(siteConfig.api.domain + 'news/' + siteConfig.api.company + '?referrer=' + siteConfig.api.referrer).then(news => {
      let counter = 0;
      news.items.forEach(item => {
        if (counter < siteConfig.maxNewsItems) {
          counter++;
          insertNewsItem(item);
        }
      });

      removeNewsLoadingSpinner();

      // Initialise the lightbox with our news images.
      refreshFsLightbox();

    }).catch(() => {
      newsContainer.innerHTML = '<div class="col-12 text-danger">Something went wrong, we couldn\'t load the latest news. Please try again in a few minutes.</div>';
      removeNewsLoadingSpinner();
    });
  }
});